.table-block {
    padding: 20px;
    height: calc(100vh - 42px);

    &_tab {
        padding: 5px;
        height: calc(100vh - 200px);
    }

    &_without-label {
        .scroll-table {
            max-height: calc(100vh - 130px);
        }
    }

    &__header {
        .search-input {
            margin-left: 5px;
            > input {
                height: 36px;
            }
        }
        .span-btn {
            display: inline-block;
        }
    }

    &__footer {
        min-height: 40px;
        > .grid {
            margin: 0 !important;
            padding: 12px 0 0 0 !important;
        }
        .row {
            padding: 0 !important;
        }

        .column {
            &:first-child {
                padding-left: 0 !important;
            }
            &:last-child {
                padding-right: 0 !important;
            }
        }
    }
}

.grid-check-all {
    height: 100%;
    display: inline;
}

.table-loader {
    height: 50px;
    position: relative;
}

.table-td {
    max-width: 500px;
    cursor: pointer;
}

.ui.selectable.table tbody tr{
    &.caseExpired {
        background-color: rgba(255, 71, 71, 0.5);
        &:hover{
            background: rgba(255, 0, 0, 0.68) !important;
        }
    }
    &.caseCloseToExpire, &.factGateInactive {
        background-color: rgba(253, 244, 153, 0.66);
        &:hover{
            background: rgba(255, 183, 0, 0.53) !important;
        }
    }
}

.value-label {
    margin: 1.5px !important;
    font-size: 11.5px !important;
}

.state-value {
    white-space: nowrap;

    &__icon {
        color: #aaa !important
    }
}

.count {
    margin-right: 20px;
    font-size: 13px;
}

.edit-value {
    display: flex;
    white-space: nowrap;

    .field, input {
        max-width: 100px !important;
    }

    &__btns {
        padding-left: 5px;
        margin-top: auto;
        margin-bottom: auto;
    }

    &__val {
        margin-top: auto;
        margin-bottom: auto;
    }
}

.backlights {
    display: flex;
    justify-content: end;
    flex-wrap: wrap;

    &__toggle {
        font-weight: bold;
        margin-top: auto;
        margin-bottom: 9px;
        margin-right: 40px;
        min-height: 21px !important;
        font-size: 12px !important;
    }

    &__checkbox {
        display: flex;
        text-wrap: nowrap !important;

        cursor: pointer;

        background-color: #b2b2b2;
        width: max-content;

        padding: 4px 8px 4px 6px;
        margin-right: 5px;
        margin-bottom: 5px;

        font-size: 12px;
        font-weight: bold;
        border-radius: 4px;

        .checkbox {
            padding-top: 1px;
            margin-right: 5px;
        }
    }
}

.object-viewer {
    min-height: 50px;
    min-width: 150px;

    &__edit-btn {
        position: absolute;
        right: 0;
    }

    &__value {
        font-weight: bold;
        margin-bottom: 7px;

        &:first-child {
            padding-right: 50px;
        }

        &-block {

        }
    }

    &__title {
        font-weight: normal;
        font-size: 12px !important;
    }

    &__obj {
        &-values {
            width: 420px !important;
            border: 1px solid rgba(34,36,38,.1);
            border-radius: 4px;
            padding: 5px;
            display: grid;
            grid-template-columns: auto auto auto;
            grid-auto-flow: row dense;

            & .object-viewer__value-block:nth-child(4) {
                grid-column: span 3;
            }
        }
    }

    &__docs {
        min-height: 70px;
        margin-top: 10px !important;

        .file-item {
            max-width: 160px;
        }

        .file-info {
            font-size: 10px !important;
        }
    }
}

.value-popup {
    z-index: 10 !important;
}

.no-data {
    font-size: 12px !important;
    color: rgba(34,36,38,.4);
}

.value-text {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 300px;
}

.value-text_val {
    max-width: 100%;
    overflow: hidden;
}