.field-matrix {
    .menu {
        margin-top: 0 !important;
    }

    &__ribbon {
        &:first-child > td {
            border-top: 0 !important;
        }
        > td {
            background-color: #fafafb;
        }
    }

    &__header {
        z-index: 3 !important;
    }

    &__table {
        overflow: auto;
        max-height: calc(100vh - 140px);
        border: 1px solid rgba(34,36,38,.1) !important;

        table {
            border: 0 !important;
        }

        thead {
            position: sticky;
            top: 0;
            z-index: 1;
        }

        .ui.dropdown {
            white-space: nowrap
        }
    }

    &__first-col {
        max-width: 360px !important;
        width: 20% !important;
    }

    &__field-name {
        display: flex;
        justify-content: space-between;
        > div {
            margin-top: auto;
            margin-bottom: auto;
        }
    }

    &__menu-item {
        padding: 7px 10px !important;
        width: 210px;
        > .field {
            width: 100%;
            .dropdown, input {
                min-height: 31px !important;
                padding: 7px 7px 7px 10px !important;
            }

            .ui.dropdown .menu>div.item[role='option'] {
                padding: 3px 7px 3px 11px !important;
            }
        }
    }
}