.cell-field {
    .react-datepicker {
        width: 298px !important;
    }
}

.edit-create-table {
    max-height: 50vh;
}

.schedule-input {
    display: flex;
    justify-content: space-between;

    .field {
        margin-top: auto !important;
        margin-bottom: auto !important;
        text-align: left;
    }

    > .field {
        &:first-child {
            min-width: 200px;
            width: 100%;
            margin-right: 15px !important;
        }
    }

    &__times {
        display: flex;

        > .field {
            width: 82px;
            input {
                padding: 2px 4px 5px 7px !important;
            }
            &:not(:last-child) {
                margin-right: 5px !important;
            }
        }
    }
}

.date-table-style {
    .react-datepicker-popper {
        transform: none !important;
        position: fixed !important;
        opacity: 0;
    }
}

.min-size-table {
    max-height: none;

    input:not(.search) {
        width: 100% !important;
        min-width: 70px !important;
    }

    .schedule-input > .field:first-child {
        min-width: 100px !important;
    }

    .hc-actions, .sticky-header-tb {
        position: relative !important;
        z-index: 1 !important;
    }
}

.virtualization-table {
    outline: 1px solid rgba(34, 36, 38, .1);

    & > div:nth-child(1) {
        .ReactVirtualized__Grid {
            overflow-y: scroll !important;
        }

        &:after {
            right: 0;
            top: 0;
            position: absolute;
            content: "";
            display: block;
            width: 10px;
            height: 100%;
            background: white;
        }
    }

    & > div:nth-child(2) {
        position: absolute;
        top: 0;
        right: 0;

        .ReactVirtualized__Grid {
            overflow-x: hidden !important;
        }
    }

    &__col {
        border-left: 1px solid rgba(34, 36, 38, .1);
        border-bottom: 1px solid rgba(34, 36, 38, .1);
        padding: 5px;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .field {
        }

        &_first {
            border-left: 0;
        }

        &_last {
            background-color: white;
            padding-right: 10px;
        }

        @media screen and (min-height: 600px) {
            .ui.search.dropdown .menu {
                max-height: 130px;
            }
        }

        @media screen and (min-height: 900px) {
            .ui.search.dropdown .menu {
                max-height: 200px;
            }
        }
    }

    &__row {
        &_first {
            background-color: #f9fafb !important;
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            font-weight: bold;
        }
    }

    &__last-col {
        background-color: white;
    }
}