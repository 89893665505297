.history-table {
    margin: 0 !important;
    padding: 12px 0 0 0 !important;
    height: auto !important;

    th {
        font-size: 13px;
    }

    .scroll-table {
        max-height: 420px !important;
    }

    &_requests {
        .scroll-table {
            max-height: 373px !important;
        }
    }
}

.security__messages {
    .message {
        margin-bottom: 20px !important;
    }
}