.radio-buttons {
    display: flex;
    justify-content: space-between;

    &_items {
        .ui.radio.checkbox {
            padding-left: 15px;
            padding-right: 15px;
        }
    }
}

.checkboxes-field {
    max-height: 50vh;
    overflow: auto;

    > .field:not(:first-child) > label {
        margin-top: 20px !important;
    }

    > .field > label {
        margin-bottom: 10px !important;
    }
}

.dropdown-with-all {
    &.segment {
        padding: 8.21px !important;
        box-shadow: none !important;
    }

    &__checkboxes {
        display: flex;

        > .field {
            width: 50%;
            margin-bottom: 0 !important;
        }

        .field {
            > .checkbox {
                margin-bottom: 0 !important;
            }
        }

        & ~ .field {
            margin-top: 7px !important;
        }
    }
}

.mini-text {
    font-size: 11px;

    &_hint {
        color: #bbc2c5;
    }

    &_error {
        color: #9f3a38;
    }
}

.visibility-item {
    height: 0 !important;
    min-height: 0 !important;
}


input[type=time] {
    padding: .547em 1em !important;
}

.w-color-sketch {
    margin-left: 0 !important;
    box-shadow: none !important;

    .w-color-editable-input {
        input:not(.search) {
            min-width: auto !important;
        }
    }
}

.color-input {
    > i.icon {
        height: calc(100% - 2px) !important;
        right: 1px !important;
        top: 1px !important;
        border-radius: 0 3px 3px 0 !important;
    }
}

.dropdown-edit-label {
    padding-right: 0 !important;

    &_is-edit {
        background-color: white !important;
    }

    > .icon {
        margin-left: 4px !important;
        cursor: pointer;

        &:hover {
            color: #2185d0;
        }
    }
}

#portal-id-display-none {
    display: none !important;
}

.edit-date-popup {
    padding: 0 !important;

    > div {
        height: auto !important;
        display: flex;
    }

    .react-datepicker {
        border: 0 !important;
        width: max-content;
    }
}

.date-time {
    margin-bottom: 0 !important;
}