.form-fields {
    display: flex;
    flex-wrap: wrap;
    > .field {
        width: 100%;
        margin-top: auto !important;
    }
    &_block {
        width: 100%;
        > .field:last-child {
            margin-bottom: 1em !important;
        }
    }
    &_custom > .field {
        margin-top: auto !important;
        margin-bottom: 14px !important;
    }
    &__label {
        font-size: 13px;
        padding-left: 10px;
        font-weight: bold;
    }

    .checkbox {
        margin-bottom: 12px !important;
    }
}

.section-form {
    .card {
        margin-top: 8px !important;
        padding: 10px !important;
        width: 100% !important;
    }
}