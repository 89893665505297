.centered-div {
    width: 509px;
    height: 160px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}

.password-with-eye {
    position: relative;
}

.password-with-eye > i {
    position: absolute;
    top: 30px;
    right: 10px;
    z-index: 9999;
}