$borderColor: rgba(0, 0, 0, 0.35);

.gate-reviews {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 45px);
    padding: 20px;

    &__top-block {
        display: flex;

        & > div {
            &:first-child {
                width: 30%;
            }

            &:last-child {
                width: 70%;
            }
        }
    }

    &__hidden-corridors-btn {
        margin-left: 30px !important;
        font-size: 12px;
        color: #2185d0;
        cursor: pointer;
        font-weight: normal;
        text-decoration: underline;
    }

    &__filters {

    }

    &__main {
        padding: 10px;
        width: 100%;
        flex-grow: 1;
        overflow: hidden;
    }

    &__btn {
        padding: 9px;
        width: 100%;
        display: flex;
        justify-content: center;

        & > .button {
            width: 31.8%;
            margin: 0 !important;
        }
    }

    &__table {
        overflow: auto;
        border: 1px solid $borderColor;

        .table {
            border-radius: 0 !important;
            border: 0 !important;
        }

        thead {
            position: sticky;
            top: 0;
            z-index: 9;
        }

        th:first-child, td:first-child {
            background: #f9fafb;
            position: sticky;
            left: 0;
            border-right: 1px solid $borderColor !important;
        }

        th:first-child {
            min-width: 150px !important;
        }

        th, td {
            padding: 6px !important;
            min-width: 50px;
        }

        th:nth-child(2), td:nth-child(2) {
            border-left: 0 !important;
        }

        th, td {
            border-color: $borderColor !important;
        }
    }

    &__first-hc {
        > div:first-child {
            text-align: end;
        }
        > div:last-child {
            text-align: start;
        }
    }
}

.legend {
    padding-left: 10px;
    padding-right: 10px;

    &__title {
        font-weight: bold;
        margin-bottom: 10px;
    }

    &__item {
        display: flex;
        margin-bottom: 5px;

        > div:first-child {
            margin-right: 5px;
        }
    }

    &__circle {
        $size: 23px;

        width: $size;
        height: $size;

        border: 1px solid $borderColor;
        border-radius: 50%;

        > .icon {
            margin: 1px 2px;
        }
    }
}

.background-color {
    &_0 {
        background: rgba(255, 255, 0, 0.22);
    }
    &_1 {
        background: rgba(0, 255, 21, 0.34);
    }
    &_2 {
        background: rgba(0, 191, 255, 0.31);
    }
    &_3 {
        background: rgba(0, 0, 0, 0.13);
    }
    &_4 {
        background: white;
    }
}

.hidden-corridors {
    &__item {
        padding: 3px;
    }
}