.representation {
    z-index: 100;
    .field {
        display: flex;

        > .dropdown {
            width: 250px !important;
            margin-right: 6px;
        }

        label {
            padding-top: 8px;
        }

        .menu {
            max-height: 80vh !important;
        }
    }
}

.grid-card-actions {
    display: flex;
    justify-content: space-between;
}