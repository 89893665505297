.cards-form {
    padding: 15px 0 !important;

    &__segment {
        padding: 0 12px 10px 12px !important;
        max-height: calc(100vh - 200px);
        overflow-y: auto;
    }

    &__btns {
        text-align: end;
        margin-top: 20px;
        padding-right: 7px;
    }
}

.card-form {
    width: 100% !important;

    &__card-header {
        display: flex;
        justify-content: space-between;

        .icon {
            text-align: end;
            margin-right: 0;
            margin-left: auto;
            cursor: pointer;
        }
    }
}