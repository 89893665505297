.facet-input {
    position: relative;
    min-width: 200px;
}

.hc-filter {
    display: flex;
    justify-content: space-between;
}

.filter-popup {
    &.ui.bottom.popup:before {
        display: none !important;
    }
}

.filter-component {
    margin-top: auto;
    margin-bottom: auto;
    display: flex;

    &__sort-buttons {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 15px;
        height: 20px;
        > .icon {
            position: absolute;
            height: 10px;

            &:before {
                position: absolute;
                line-height: 7px;
                top: 1px;
                left: 4px;
            }

            &:first-child {
                top: -2px;
            }
            &:last-child {
                bottom: -2px;
            }
        }
    }
}

.reset-selected {
    display: flex;
    justify-content: end;
    color: rgb(38, 96, 148);
    cursor: pointer;
    text-decoration: underline;
    font-size: 11px;
    font-weight: bold;
}

.filter-popup {
    .fields {
        margin-bottom: 2px !important;
        > .field {
            padding: 2px !important;
        }
    }

    .react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type=time] {
        padding: 4px !important;
    }
}

.scroll-filter {
    max-height: calc(100vh - 600px);
    margin-top: 10px !important;

    .segment {
        min-height: 50px;
    }
}

.checkbox-label {
    display: flex !important;
    > label {
        padding-left: 0 !important;
        &:before {
            display: none;
        }
    }
}