.data-block {
    width: 100% !important;
    height: 100%;
    overflow: hidden;

    &__header {
        display: flex;
        > div {
            margin-left: 10px;
        }
    }

    .header {
        display: flex !important;
        justify-content: space-between;
        margin-bottom: 15px;
    }

    &__full-size {
        position: fixed !important;
        height: calc(100% - 20px) !important;
        width: calc(100% - 20px) !important;
        margin: 10px !important;
        top: 0;
        left: 0;
    }

    &__content {
        height: 100%;
        overflow: hidden;
    }

    &__container {
        height: inherit;
    }
}