* {
    margin: 0;
    padding: 0;
}

html {
    font-size: 10pt
}

body {
    min-width: 1028px !important;
    font-size: 13px
}

.container-margin-top-bottom {
    margin-top: 25px;
    margin-bottom: 70px;
}

.width-auto {
    width: auto !important;
}

.padding-l5-r5 {
    padding-right: 5% !important;
    padding-left: 5% !important;
}

.padding-t3-b3 {
    padding-top: 3% !important;
    padding-bottom: 3% !important;
}

.padding-l2-r2 {
    padding-right: 2% !important;
    padding-left: 2% !important;
}

.scroll-table {
    position: relative !important;
    overflow: auto !important;
    max-height: calc(100vh - 180px);
    border: 1px solid #ddd;
}

.modal-table {
    height: 50vh;
}

.scroll-table_12 {
    font-size: 12px;
}

.scroll-table__nowrap {
    white-space: nowrap !important;
}

.scroll-table table{
    border: 0 !important;
}

.sticky-header-tb {
    position: sticky !important;
    position: -webkit-sticky !important;
    top: 0;
    z-index: 10;
}

.ui.dimmer {
    /*background-color: rgba(0,0,0,.6) !important;*/
}

.ui.grid {
    margin-bottom: 1px;
}

.ui.table {
    margin-top: 0 !important;
}

.ui.table .ui.button {
    margin-top: 1px;
    margin-bottom: 1px;
}
.hidden-resize{
    white-space: nowrap;
    opacity:0;
    height: 0;
}

.ui.active.tab {
    margin-top: 1px;
}

.item.large {
    font-size: 14px;
    line-height: 14px;
}

.normal-weight {
    font-weight: normal !important;
}

.normal-weight > .text {
    font-weight: normal !important;
}

.blurring.dimmed.dimmable > :not(.dimmer) {
    -webkit-filter: none !important;
    filter: none !important;
}

.blurring.dimmed.dimmable article > :not(.dimmer) {
    -webkit-filter: blur(5px) grayscale(.7) !important;
    filter: blur(5px) grayscale(.7) !important;
}

.opacity-half {
    opacity: 0.5;
}

.login-form-input-wrapper .input {
    width: 100%;
}

.ui.menu {
    border-radius: 0 !important;
}

.ui.secondary.menu .item > img:not(.ui) {
    width: 8em !important;
    margin-right: 2em;
}

.ui.secondary.menu .dropdown.item > .menu, .ui.text.menu .dropdown.item > .menu {
    border-radius: 0;
    margin-top: 0;
}

.ui.secondary.pointing.menu .item .active.item {
    border-color: transparent;
}

.ui.secondary.pointing.menu .active.item.dropdown {
    font-weight: initial;
}

.ui.secondary.pointing.menu .item.dropdown.superActive {
    border-color: #1b1c1d;
    font-weight: 700;
}

.ui.secondary.menu .header.item {
    padding: 0.5em 1.5em !important
}

.ui.label {
    margin-bottom: 5px;
}

.grid > .column > h2 {
    margin-top: 1.2em;
}

@media only screen and (min-width: 1500px) {
    .ui.wider.container {
        width: calc(1400px) !important;
    }
}

.padding-margin-0 {
    padding: 0 !important;
    margin: 0 !important;
}

.padding-bottom-0 {
    padding-bottom: 0 !important;
}

.margin-bottom-10 {
    margin-bottom: 10px !important;
}

.margin-10 {
    margin: 10px !important;
}

.margin-bottom-15 {
    margin-bottom: 15px !important;
}

.margin-bottom-5 {
    margin-bottom: 5px !important;
}

.margin-bottom-2 {
    margin-bottom: 2px !important;
}

.margin-top-2em {
    margin-top: 2em !important;
}

.margin-top-7 {
    margin-top: 7px !important;
}


.margin-top-15 {
    margin-top: 15px !important;
}

.margin-top-20 {
    margin-top: 20px !important;
}

.padding-margin-0 {
    padding: 0 !important;
    margin: 0 !important;
}

.ui.button > .icon:not(.button) {
    margin: 0 !important;
}

.padding-bottom-0 {
    padding-bottom: 0 !important;
}

.margin-bottom-0 {
    margin-bottom: 0 !important;
}

.margin-top-0 {
    margin-top: 0 !important;
}

.margin-bottom-10 {
    margin-bottom: 10px !important;
}

.margin-bottom-5 {
    margin-bottom: 5px !important;
}

.margin-left-15 {
    margin-left: 15px !important;
}

.margin-left-5 {
    margin-left: 5px !important;
}

.text-align-r {
    text-align: right;
}

.margin-0 {
    margin: 0 !important;
}

.d-none {
    display: none !important;
}

.border-bottom-0 {
    border-bottom: none !important;
}

.pl-5 {
    padding-left: 5px;
}

.padding-0 {
    padding: 0 !important;
}

.padding-right-15 {
    padding-right: 15px !important;
}

.padding-left-right-5 {
    padding: 0 5px !important;
}

.padding-20 {
    padding: 20px !important;
}

.padding-top-20 {
    padding-top: 20px !important;
}

.padding-top-0 {
    padding-top: 0 !important;
}

.margin-bottom-label {
    margin: 0 0 .28571429rem 0;
}

.visits {
    padding: 15px 15px 0 15px;
}

.add-visit {
    width: 92px;
}

.search-line-wrapper {
    padding-left: 33px !important;
    padding-top: 7px !important;
}

.record-count-wrapper {
    padding-top: 10px;
    width: 86px !important;
    min-width: 86px !important;
    max-width: 86px !important;
}

.change-view-btn {
    padding: 15px !important;
}

.change-view-variant-wrapper div {
    padding: 5px;
}

.see-more-contacts {
    position: absolute;
    font-weight: 400;
    font-size: 12px;
    bottom: -11px;
    left: 98px;
    text-decoration: underline;
}

.visit-orders-column {
    font-weight: 600;
    padding-top: 10px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.visit-orders-column.facet {
    padding-top: 7px;
}

.facet-filter-for-visit-orders {
    width: 131px !important;
    min-width: 131px !important;
}

.visit-tk-column {
    font-weight: 600;
    padding-top: 10px;
}

.visit-tk-column.facet {
    padding-top: 7px;
}

.facet-filter-for-visit-tk input {
    width: 100%;
}

.visit-client-column {
    width: 145px !important;
    min-width: 145px !important;
    max-width: 145px !important;
    font-weight: 600;
    padding-top: 10px;
}

.visit-client-column.facet {
    padding-top: 7px;
}

.facet-filter-for-visit-client {
    width: 131px !important;
    min-width: 131px !important;
}

.main-logo-container {
    padding: 0 !important;
}

.main-logo-container img {
    width: 127px !important;
}

.visit-modal {
    width: 575px !important;
}

.visit-modal-platform-label {
    padding-top: 8px;
}

.visit-modal-platform-select {
    width: 100% !important;
}

.car-number-input {
    width: 114px;
}

.visit-modal-partner {
    min-width: 121px !important;
}

.visit-modal-task-type {
    min-width: 128px !important;
}

.visit-modal-task-date {
    width: 150px !important;
}

.visit-modal-task-task-gate {
    min-width: 53px !important;
    width: 53px !important;
}

.popover-task-content {
    width: 300px;
}

.inline-edit-in-column {
    text-decoration: dotted;
}

.popover-tk-select-content {
    width: 300px;
}

.popover-tk-select-content .dropdown {
    margin-bottom: 15px;
}

.admin-fields-sortable {
    width: 270px;
}

.admin-fields-sortable ul {
    padding-left: 0 !important;
}

.admin-fields-sortable li {
    list-style: none;
    border: 1px solid rgba(34, 36, 38, 0.15);
    border-radius: 4px;
    padding: 10px;
    margin: 5px;
    cursor: grab;
    padding-right: 57px;
    position: relative;
}

.progress-wrapper {
    padding-top: 8px;
}

.need-confirm-driver {
    position: absolute;
    font-size: 12px;
    bottom: 16px;
    left: 177px;
}

.driver-confirm-modal > div {
    margin-bottom: 10px;
    margin-top: 10px;
}

.dropzone {
    width: 100% !important;
    position: relative;
    height: 200px;
    border-width: 2px;
    border-color: rgb(102, 102, 102);
    border-style: dashed;
    border-radius: 5px;
    padding: 80px;
}

.import-vosots-modal .ui.positive.button {
    margin-bottom: 15px;
}

.document-item {
    font-weight: 600;
    margin-top: 15px;
    margin-bottom: 15px;
}

.image-fit {
    height: 90vh !important;
    width: 100%;
    object-fit: contain;
}

.language-switcher {
    position: absolute;
    right: 82px;
    width: 224px;
    top: 33px;
}

.language-switcher .divider.text > i {
    display: none;
}

.send-request {
    position: absolute;
    left: 82px;
    top: 26px;
}

.action-fileId {
    position: absolute;
    right: 2px;
    top: 6px;
    cursor: pointer;
}

.DayPicker-Day--birthday {
    background-color: #21ba45;
    color: white;
}

.DayPicker-Day--monday {
    background-color: #fbbd08;
    color: #00bcd4;
}

/* .column .ui.input {
    width: 100% !important;
} */

.visits-results-container {
    margin: 0;
    padding-left: 15px;
    padding-top: 15px;
    width: 1429px;
    height: 759px;
    overflow-x: scroll;
    overflow-y: hidden;
}

.visits-results-container .ui.message {
    width: 100%;
}

.label-margin {
    margin: 3px !important;
}

.react-datepicker-wrapper {
    display: block !important;
}

.react-datepicker__input-container {
    width: 100% !important;
}

.add-btn {
    min-width: 50px;
    margin: 0 !important;
}

.visit-edit-grid .add-btn {
    min-width: 50px;
}

.small-add-block {
    height: 100%;
}

.small-add-block .add-btn {
    width: 50px !important;
    height: 100%
}

.small-add-btn-block {
    position: absolute;
    right: 14px;
    top: 30px;
}

.small-edit-btn-block {
    position: absolute;
    right: 30px;
    height: 36px;
    top: 20px;
    margin: 0 !important;
}

.small-edit-btn-block .add-btn {
    min-width: 50px;
    width: 50px;
    height: 100%;
}

.small-edit-btn-block .add-btn .icon {
    margin: 0 !important;
}

.small-add-btn-block .add-btn {
    min-width: 50px;
    width: 50px;
}

.dropbox-button {
    position: relative;
    display: flex;
}

.dropbox-button button {
    margin-left: 10px !important;
    margin-right: 0 !important;
}

.btn-add {
    padding: 11px !important;
}

.dropbox-button_2 {
    padding-right: 110px;
}

.dropbox-button .dropdown {
    width: 100%
}

.dropbox-button .add-btn {
    width: 50px;
    position: absolute;
    right: -3.5px;
    top: 0;
    bottom: 0;
}

.dropbox-button .edit-btn {
    width: 50px;
    position: absolute;
    right: -55px;
    top: 0;
    bottom: 0;
}

.dropbox-button_2 .add-btn {
    right: 55px;
}

.dropbox-button_2 .edit-btn {
    right: 0;
}

@media only screen and (max-width: 991px) {
    .add-btn {
        min-width: initial;
    }
}

table .add-btn {
    margin: 0 !important;
    float: none !important;
}

.field > .ui.segment {
    margin: 0 !important;
}

.history-who {
    font-size: 12px;
    font-weight: bold;
    word-break: break-word;
}

.history-comment {
    white-space: pre-wrap;
    word-break: break-all;
}

.form p {
    margin-top: 0 !important;
}

/***********VISITS*************************/
.scroll-grid-container {
    display: grid;
    overflow: auto;
    width: 100%;
    border: 1px solid #ddd;
    border-radius: 5px;
}

.visits-table {
    position: relative;
    border-collapse: collapse;
    border: none !important;
}

.sticky-header {
    border-top: none !important;
}

.sticky-header th {
    /*min-width: 110px;*/
    /*max-width: 400px;*/
    position: sticky !important;
    position: -webkit-sticky !important;
    top: 0;
    z-index: 1;
    border-bottom: 1px solid #ddd !important;
    font-size: 12.5px !important;
    background-attachment: local !important;
}

.sticky-header > .actions-column {
    right: 0;
    min-width: 150px;
    width: 150px;
}

.sticky-header > .actions-column:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: -15px;
    width: 15px;
    background: linear-gradient(to right, rgba(249, 250, 251, 0) 0%, rgba(249, 250, 251, 1) 100%);
}

.sticky-header th:not(.small-column) {
    padding-left: 0 !important;
}

.sticky-header th:not(.small-column) .input {
    width: 100%;
}

.sticky-header th:not(.small-column) .input input[type=text] {
    padding-left: 10px !important;
    padding-right: 5px !important;
}

.sticky-header th:not(.small-column) .input input, .sticky-header th:not(.small-column) .input .label {
    border-color: #ccc
}

.visit-row > td > div {
    max-height: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 7;
    -webkit-box-orient: vertical;
}

.visit-row td {
    cursor: pointer;
    background-color: #fbfdff;
}

.visit-row .checkbox, .visit-row .checkbox input.hidden + label {
    cursor: default;
}

.visit-row:hover td {
    background-color: #deecff;
}

.visit-row td, .visit-row th {
    /*min-width: 110px;*/
    /*max-width: 400px;*/
    border-top: 1px solid #ddd !important;
}

.visit-row th {
    /*min-width: 150px;*/
    /*width: 150px;*/
    position: -webkit-sticky; /* for Safari */
    position: sticky;
    right: 0;
    background: #f9fafb;
    padding: 5px 15px;
    border-left: 1px solid #ddd !important;
}

.visit-row th button {
    margin: 0 0 3px 0 !important;
    width: 100%
}

.visit-row .ui.fitted.checkbox {
    margin-top: 4.5px;
}

.small-column {
    width: 50px !important;
    min-width: 50px !important;
    padding-left: 15px !important;
}

.records-counter {
    margin-top: 8px;
    white-space: nowrap;
    margin-right: 10px;
}

.search-input {
    min-width: 200px;
    max-width: 250px;
    width: 100%;
}

.search-input input {
    height: 33px;
}

.visits-header-panel .create-button {
    height: 33px;
    min-width: 150px;
    white-space: nowrap;
    margin-right: 10px;
}

.visits-header-panel .visit-right-elements {
    text-align: right;
    white-space: nowrap;
}

.visits-header-panel .visit-right-elements .button {
    height: 33px;
    white-space: normal;
}

.visits-header-panel .visit-right-elements .button.orange {
    opacity: 0.7;
}

.visits-footer-panel {
    display: flex;
    margin-top: 0 !important;
}

.visits-footer-panel .visit-right-elements {
    text-align: right;
}

.visits-footer-panel .visit-right-elements label {
    font-size: 13px;
}

.facet input {
    width: 100%;
}

.ui.fullscreen.modal {
    left: 2.5% !important;
}

.select-facet-values {
    max-height: 60vh;
    overflow-x: hidden;
    margin-top: 10px;
    min-width: 220px;
    font-size: 12px !important;
}

.select-facet-values .field {
    margin-bottom: 7px !important;
}

.select-facet-values label {
    font-size: 13px !important;
}

.label-in-popup {
    display: block;
    margin-bottom: 7px;
}

.colorGrey label {
    color: #aaa !important;
}

.colorGrey {
    color: #aaa !important;
}

.form.small .ui.checkbox label {
    font-size: 13px !important;
}

/*******CUSTOM STYLES******/

.column-number-facet {
    min-width: 96px !important;
    width: 96px !important;
    font-weight: bold;
}

.column-state-facet {
    min-width: 200px !important;
    width: 200px !important;
    font-weight: bold;
}

/**************************/
/**************************/

.ui.table thead tr:first-child > th:first-child {
    border-radius: 0 !important;
}

.visits-table tr > td:first-child, .visits-table tr > th:first-child {
    min-width: 3px !important;
    max-width: 3px !important;
    width: 3px !important;
    padding: 0 !important;
}

.visit-row.green td {
    background-color: #21ba4542;
}

.visit-row.green > td:first-child {
    background-color: #21ba45;
}

.visit-row.blue td {
    background-color: #2185d040;
}

.visit-row.blue > td:first-child {
    background-color: #2185d0;
}

.visit-row.olive td {
    background-color: #b5cc1845;
}

.visit-row.olive > td:first-child {
    background-color: #b5cc18;
}

.visit-row.orange td {
    background-color: #f2711c45;
}

.visit-row.orange > td:first-child {
    background-color: #f2711c;
}

.visit-row.brown td {
    background-color: #a5673f47;
}

.visit-row.brown > td:first-child {
    background-color: #a5673f;
}

.visit-row.yellow td {
    background-color: #fbbd084a;
}

.visit-row.yellow > td:first-child {
    background-color: #fbbd08;
}

.visit-row.teal td {
    background-color: #00b5ad4a;
}

.visit-row.teal > td:first-child {
    background-color: #00b5ad;
}

.visit-row.violet td {
    background-color: #6435c947;
}

.visit-row.violet > td:first-child {
    background-color: #6435c9;
}

.visit-row.purple td {
    background-color: #a333c847;
}

.visit-row.purple > td:first-child {
    background-color: #a333c8;
}

.visit-row.red td {
    background-color: #db28284d;
}

.visit-row.red > td:first-child {
    background-color: #db2828;
}

.label .ui.checkbox label {
    color: white;
}

.visits-table tbody > tr:last-child > td,
.visits-table tbody > tr:last-child > th {
    border-bottom: 1px solid #ddd !important;
}

/***************Notifications*******************/

.Toastify__toast {
    min-height: 1em;
    line-height: 1.4285em;
    border-radius: .28571429rem !important;
    -webkit-box-shadow: 0 0 0 1px rgba(34, 36, 38, .22) inset, 0 0 0 0 transparent;
    box-shadow: 0 0 0 1px rgba(34, 36, 38, .22) inset, 0 0 0 0 transparent;
}

.Toastify__toast--info {
    -webkit-box-shadow: 0 0 0 1px #a9d5de inset, 0 0 0 0 transparent !important;
    box-shadow: 0 0 0 1px #a9d5de inset, 0 0 0 0 transparent !important;
    background-color: #f8ffff !important;
    color: #276f86 !important;
}

.Toastify__toast--error {
    -webkit-box-shadow: 0 0 0 1px #e0b4b4 inset, 0 0 0 0 transparent !important;
    box-shadow: 0 0 0 1px #e0b4b4 inset, 0 0 0 0 transparent !important;
    background-color: #fff6f6 !important;
    color: #9f3a38 !important;
}

.Toastify__toast--warning {
    -webkit-box-shadow: 0 0 0 1px #c9ba9b inset, 0 0 0 0 transparent !important;
    box-shadow: 0 0 0 1px #c9ba9b inset, 0 0 0 0 transparent !important;
    background-color: #fffaf3 !important;
    color: #573a08 !important;
}

.Toastify__close-button {
    color: #1b1c1d !important;
}

/************************Sort***************************/

.facet-sortable {
    padding-right: 20px !important;
}

.sort-button {
    height: 16px !important;
    min-height: 10px !important;
    width: 19px !important;
    margin: 0 !important;
    padding: 0 !important;
    background: white !important;
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important;
    font-size: 18px;
    text-align: center;
    overflow: hidden;
}

.facet-sortable .sort-button {
    background: transparent !important;
}

.sort-button .icon {
    display: block;
    margin: 0 !important;
    color: #ccc !important;
}

.sort-button:not(.sort-button-active):hover .icon {
    color: rgb(155, 155, 155) !important;
}

.sort-button-active .icon {
    color: rgb(100, 100, 100) !important;
}

.sort-button-up {
    position: absolute;
    top: 2px;
    right: 1px;
    padding-top: 3px !important;
    padding-bottom: 2px !important;
}

.sort-button-down {

    display: block;
    position: absolute;
    bottom: 1px;
    right: 1px;
    padding-bottom: 4px !important;
}

.sort-button-down > i.icon {
    margin-top: -4px !important;
}

/************VISIT EDIT****************/

textarea.mini {
    font-size: .78571429em !important;
}

.popup.wide {
    min-width: 300px;
}

.visit-edit-grid, .specifications-gate-block {
    display: -webkit-flex;
    -webkit-flex-wrap: wrap;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    justify-content: flex-start;
    padding: 0 -15px;
}

.visit-edit-grid > .field:not(.wide) {
    width: 25% !important;
}

.specifications-gate-block > .field:not(.wide) {
    width: 15% !important;
}

.visit-edit-grid > .field.wide {
    width: 100% !important;
}

.visit-edit-grid > .field {
    margin-bottom: 15px !important;
    padding: 0 15px !important;
}

.specifications-gate-block > .field {
    margin-bottom: 15px !important;
    padding: 0 15px 0 0 !important;
}

.visit-edit-grid > .field input[type=text] {
    width: 100% !important;
}

.security-check {
    /*padding-right: 15px;*/
    display: flex;
    flex-wrap: wrap;
}

.security-check > .field:not(.wide),
.security-check > div > .field:not(.wide) {
    width: 100% !important;
    display: flex;
    align-items: center;
    position: relative;
}

.security-check > .field:not(.wide) .mini-text,
.security-check > div > .field:not(.wide) .mini-text {
    bottom: -15px;
    position: absolute;
}

.security-check > .field:not(.wide) > label,
.security-check > div > .field:not(.wide) > label {
    padding-right: 12px;
}

.security-check > .field.wide,
.security-check > div > .field.wide {
    width: 100% !important;
}

.security-check > .field > .dropbox-button,
.security-check > div > .field > .dropbox-button {
    display: flex;
    width: 100%;
    padding-right: 0;
}

.security-check > .field > .dropbox-button > .dropdown,
.security-check > div > .field > .dropbox-button > .dropdown {
    margin: 0 15px;
}

.security-check > .field > .dropbox-button > .button,
.security-check > div > .field > .dropbox-button > .button {
    position: relative;
    right: 0;
}

.state-edit-field {
    white-space: nowrap;
}


.semantic-ui-div {
    font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
    margin: 0;
    outline: 0;
    width: 100%;
    height: 38px;
    line-height: 1.21428571em;
    padding: .67857143em 1em;
    font-size: 1em;
    background: #fff;
    border: 1px solid rgba(34, 36, 38, .15);
    color: rgba(0, 0, 0, .87);
    border-radius: .28571429rem;
}

.disabled :disabled {
    opacity: 1 !important;
}

.ui.disabled.input, .ui.form .disabled.field, .ui.form .disabled.fields .field, .react-datepicker__input-container :disabled {
    opacity: 0.85 !important;
}

.ui.disabled.search {
    opacity: 0.85 !important;
}

.visit-modal {
    min-height: 65vh;
}

.visit-modal > .content {
    padding-bottom: 85px !important;
    border-bottom-left-radius: .28571429rem;
    border-bottom-right-radius: .28571429rem;
}

.visit-modal > .actions {
    min-height: 65px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}

.visit-modal .attached.tab .ui.attached.tabular.menu .active.item {
    background-color: #fcfcfc !important;
}

.visit-modal .attached.tab .attached.tab {
    background-color: #fcfcfc !important;
}

.form-modal > .content {
    min-height: 60vh;
}

.form-modal > .actions {
    min-height: 62px;
}

.ui.modal > .header {
    margin-bottom: 1px;
}

.task-tab {
    padding: 0 !important;
    position: relative;
}

.task-tab > .content {
    padding: 14px 14px 58px 14px;
}

.task-tab > .actions {
    min-height: 44px;
    padding: 7px !important;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}

.task-info-tab {
    padding: 0 !important;
}

.task-info-tab .visit-edit-grid {
    padding: 20px 0 10px 0 !important;
}

.tab > .actions {
    background: #f9fafb;
    padding: 1rem 1rem;
    border-top: 1px solid rgba(34, 36, 38, .15);
    text-align: right;
}

.add-button-tab-item {
    padding: 0 !important;
    border-radius: 0 !important;
}

.add-task-button {
    padding: 12px !important;
    border-radius: 0 !important;
    width: 100% !important;
    background: transparent !important;
}

.all-tabs > .ui.menu.tabular {
    flex-wrap: wrap;
}

.ui.menu:not(.vertical) .item > .button.tiny {
    font-size: .85714286rem !important;
}

.react-datepicker__close-icon::after {
    background-color: transparent !important;
    border-radius: 0 !important;
    color: black !important;
    height: 16px;
    width: 16px;
    line-height: 1;
    margin: -10px auto 0;
    position: absolute;
    right: 0;
    text-align: center;
    font-size: 17px !important;
    font-weight: 800 !important;
}

.ui.dropdown .menu > .item > .description {
    font-size: 10px;
    color: black;
    width: 65px;
    text-align: right;
}

.gate-input > .menu > .item > .description {
    width: 70% !important;
}

.ui.selection.dropdown .menu > .item .text {
    line-height: 26px;
}

.ui.selection.dropdown .menu > .item {
    padding: .58571429rem 1.14285714rem !important;
    min-height: 30px;
}

.ui.selection.dropdown {
    min-width: 2em !important;
}

/****************************/

/*******DOCUMENTS*************/
.flex-container {
    padding: 0;
    margin: 0 -5px;
    list-style: none;
    -ms-box-orient: horizontal;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -moz-flex;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
}

.flex-container-justify {
    display: flex;
    justify-content: space-between;
    position: relative;
}

.file-item {
    width: 200px;
    margin: 5px;
    height: 97px;
    overflow: hidden;
    padding: 5px 5px 5px 80px;
    border: 1px solid #d8d8d8;
    border-radius: 2px;
    position: relative;
}

.file-item-add {
    width: 97px;
}

.file-info {
    padding-left: 3px;
    width: 100%;
    height: 60px;
    word-break: break-all;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 11px;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    line-height: 16px;
}

.file-info-red {
    color: red;
}

.file-item .image-container {
    cursor: pointer;
    position: absolute;
    left: 5px;
    top: 5px;
    height: 85px !important;
    width: 70px;
    -webkit-background-size: cover !important;
    -moz-background-size: cover !important;
    -o-background-size: cover !important;
    background-size: cover !important;
}

.image-container .icon {
    font-size: 76px;
    color: #ccc;
    margin: -12px -10px;
    line-height: 110px !important;
}

.file-item:hover .uploaded-image-delete-button,
.file-item:hover .uploaded-image-edit-button {
    display: block !important;
}

.uploaded-image-edit-button {
    position: absolute;
    right: 22px;
    bottom: 8px;
    cursor: pointer;
    display: none !important;
}

.uploaded-image-delete-button {
    color: #ed5565;
    position: absolute;
    right: 2px;
    bottom: 8px;
    cursor: pointer;
    display: none !important;
}

.uploaded-image-add-button {
    color: #d8d8d8;
    position: absolute;
    left: 30px;
    top: 26px;
    cursor: pointer;
    width: 30px !important;
    height: 40px !important;
    font-size: 30px !important;
}

.placeholder-image {
    background: url(./img/photo.svg) center no-repeat;
    cursor: pointer;
}

.hiddenfileinput {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

.uploader {
    align-items: center;
    background-color: rgba(0, 0, 0, 0.02);
    cursor: pointer;
    display: flex;
    height: 300px;
    justify-content: center;
    outline: 2px dashed #ccc;
    outline-offset: 5px;
    position: relative;
    width: 100%;
    margin-bottom: 20px;
}

.dragndrop {
    outline: 2px dashed #f08080;
    color: #f08080;
}

.uploader input {
    display: none;
}

.uploader img, .uploader .icon {
    pointer-events: none;
}

.uploader, .uploader .icon {
    transition: all 100ms ease-in;
}

.uploader .icon {
    color: #eee;
    color: rgba(0, 0, 0, 0.2);
    font-size: 5em;
}

.uploader img {
    left: 50%;
    opacity: 0;
    max-height: 100%;
    max-width: 100%;
    position: absolute;
    top: 50%;
    transition: all 300ms ease-in;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.uploader.loaded img {
    opacity: 1;
}

.ui.placeholder.segment {
    min-height: 1rem !important;
}

.ui.input input[placeholder]::placeholder {
    color: #868686;
}

.no-bottom-margin {
    margin-bottom: 0 !important;
}

.no-top-margin {
    margin-top: 0 !important;
}

.ui.segment {
    width: 100%;
}

/**************************/

.driver-popup {
    width: 460px !important;
}

.ui.form.driver-popup .disabled.field,
.ui.form.driver-popup .disabled.input,
.ui.form.driver-popup .disabled.fields .field,
.ui.form.driver-popup .field :disabled,
.ui.form.driver-popup .field.disabled > label,
.ui.form.driver-popup .fields.disabled > label {
    opacity: 1 !important;
}

.document-list-button {
    width: 20px !important;
    padding: 3px !important;
    background: white !important;
}

.flex-container-justify {
    justify-content: space-between;
}

/***************ORDERS*******************/

.order-container {
    display: grid;
    overflow: auto;
    max-height: 500px;
    width: 100%;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin-bottom: 7px;
}

.visit-edit-grid .react-datepicker-popper {
    z-index: 3 !important;
}

.order-column-item {
    min-width: 140px;
}

.order-container table {
    border: none !important;
    font-size: 12px !important;
}

/*.order-container td, .order-container th{
    min-width:120px;
}*/

.order-container td.small-column, .order-container th.small-column {
    min-width: 60px !important;
    width: 60px !important;
}

.order-sticky-header {
    border-top: none !important;
}

.order-sticky-header th {
    border-top: none !important;
    position: sticky !important;
    position: -webkit-sticky !important;
    top: 0;
    z-index: 2;
}

.select-as-semantic {
    position: relative;
}

.select-as-semantic .remove-select-button {
    position: absolute;
    background: white !important;
    right: 1px;
    top: 1px;
    bottom: 1px;
    margin: 0 !important;
    padding: 8px !important

}

.select-as-semantic select {
    font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif !important;
    margin: 0 !important;
    outline: 0 !important;
    -webkit-appearance: none !important;
    tap-highlight-color: rgba(255, 255, 255, 0) !important;
    line-height: 1.21428571em !important;
    padding: .67857143em 1em !important;
    font-size: 1em !important;
    background: #fff !important;
    border: 1px solid rgba(34, 36, 38, .15) !important;
    color: rgba(0, 0, 0, .87) !important;
    border-radius: .28571429rem !important;
    -webkit-box-shadow: 0 0 0 0 transparent inset !important;
    box-shadow: 0 0 0 0 transparent inset !important;
    -webkit-transition: color .1s ease, border-color .1s ease !important;
    transition: color .1s ease, border-color .1s ease !important;
}

.input-as-semantic {
    margin: 0;
    max-width: 100%;
    -webkit-box-flex: 1;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
    outline: 0;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    text-align: left;
    line-height: 1.21428571em;
    font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
    padding: .67857143em 1em;
    background: #fff;
    border: 1px solid rgba(34, 36, 38, .15);
    color: rgba(0, 0, 0, .87);
    border-radius: .28571429rem;
    -webkit-transition: border-color .1s ease, -webkit-box-shadow .1s ease;
    transition: border-color .1s ease, -webkit-box-shadow .1s ease;
    transition: box-shadow .1s ease, border-color .1s ease;
    transition: box-shadow .1s ease, border-color .1s ease, -webkit-box-shadow .1s ease;
    -webkit-box-shadow: none;
    box-shadow: none;
    height: 33px;
    position: relative;
}

.date_calendar_icon {
    cursor: default;
    position: absolute;
    line-height: 1;
    text-align: center;
    top: 10px;
    right: 8px;
    margin: 0;
    height: 100%;
    width: 2.67142857em;
    opacity: .5;
    border-radius: 0 .28571429rem .28571429rem 0;
    -webkit-transition: opacity .3s ease;
    transition: opacity .3s ease;
    color: rgb(146 146 146);
}

.date_calendar {
    position: relative;
}
.date_calendar input {
    width: inherit!important;
}

.ui.form.small .react-datepicker__close-icon {
    top: 17% !important
}

.ui.form.tiny .react-datepicker__close-icon {
    top: 15% !important
}

.column-datetime-order-value {
    min-width: 150px !important;
    width: 150px;
}

/***************************************/

.grey-background {
    background-color: #fcfcfc !important;
}

.no-label-datepicker .react-datepicker__triangle {
    left: 100px !important;
}

.no-label-datepicker .react-datepicker-popper[data-placement^="bottom"] {
    margin-left: -52px !important;
}


.grid-check-all {
    height: 100%;
    display: inline;
   /* align-items: center;
    flex-direction: row;*/
}

.grid-check-all .ui.checkbox {
    padding-right: 10px;
    padding-bottom: 10px;
}

.color-orange {
    color: #f2711c !important;
}

.color-red {
    color: rgb(255, 71, 71) !important;
}

.color-orange label {
    color: rgb(255, 165, 0) !important;
}

.color-red label {
    color: rgb(255, 71, 71) !important;
}

.form-reason {
    margin: 15px;
}

.security-check-table:hover {
    cursor: pointer;
}

.security-service-history-driver-table:hover {
    cursor: pointer;
}

.security-table-color-red {
    background: rgba(255, 71, 71, 0.5);
}

.ui.celled.selectable .security-table-color-red:hover, .ui.table.security-table-color-red:hover {
    background: rgba(255, 71, 71, 1) !important;
}

.security-table-color-orange {
    background: rgba(255, 165, 0, 0.5);
}

.ui.celled.selectable .security-table-color-orange:hover, .ui.table.security-table-color-orange:hover {
    background: rgba(255, 165, 0, 0.8) !important;
}

.driver-info__document {
    padding-right: 5px;
}

.dropdown-driver-decription {
    position: absolute;
    width: 90%;
    bottom: -1px;
    left: 15px;
    text-align: left;
}

@media only screen and (max-width: 1400px) {
    .dropdown-driver-decription {
        position: absolute;
        width: 90%;
        bottom: -10px;
        left: 15px;
        text-align: left;
    }
}

.status-label-bottom {
    vertical-align: bottom !important;
}

.status-label-middle {
    white-space: nowrap;
    vertical-align: middle !important;
}

.init-scroll {
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    height: 450px;
}

.transport-info {
    align-items: center !important;
}

.transport-info-document {
    height: 90px;
}

.schedule-modal-task-check {
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.datepicker-message {
    font-size: 12px !important;
    color: #9f3a38;
}

.schedule-exception-block-time {
    display: flex !important;
    padding: 5px 0 !important;
}

.red-border {
    border: 1px solid #9f3a38 !important;
}

.checkbox-red-border label:before {
    border: 1px solid #e0b4b4 !important;
    background-color: #fff6f6 !important;
}

.input-error {
    background-color: #fff6f6 !important;
    border-color: #e0b4b4 !important;
    color: #9f3a38 !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

.schedule-modal-block {
    width: 100%;
}

.shedule-modal-table {
    height: 370px;
}

.c-time-slot {
    display: block;
    /*cursor: pointer;*/
    position: relative;
    z-index: 2;
}

.c-time-slot_input {
    position: relative;
}

.c-time-slot_input input {
    cursor: pointer;
    position: relative;
}

.c-time-slot__clear {
    position: absolute;
    right: 4%;
    font-size: .85714286em !important;
    cursor: pointer !important;
    padding: .91666667em !important;
}

.c-time-slot__clear_icon {
    font-size: 10px !important;
}

.c-time-slot__icon:hover {
    color: red;
}

.c-time-slot-list {
    width: 400px !important;
    max-height: 300px;
    overflow-y: auto;
}

.c-time-slot-list__item_date {
    padding-bottom: 5px;
    font-weight: bold;
}

.c-time-slot-list__item_time {
    cursor: pointer;
}

.c-time-slot-list__item_time:hover {
    background-color: #f0f0f0 !important;
}

.c-time-slot-list__item_time_selected {
    background-color: #2a87d0 !important;
    color: white;
}

.c-time-slot-list__item_time_selected:hover {
    background-color: #1d5d90 !important;
    color: white;
}

.no-fluid-input {
    width: 275px;
}


.clear-margin-grid {
    margin: 0 !important;
}

.margin-top-for-delete {
    margin-top: 5px !important;
}

.schedule-modal-block .grid:last-child .row {
    padding-bottom: 1rem !important;
}

.avization-label {
    font-size: 12px;
    font-weight: bold;
}

.avization-empty {
    margin: 5px !important;
    align-items: center !important;
}

.fluid-for-loader {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

/* Loader UI */

.ui.loader:after {
    border-color: rgba(0, 0, 0, 0.38) transparent transparent !important;
    border-width: .27em !important;
}

.ui.loader:before {
    border-width: .27em !important;
    border-color: rgba(0, 0, 0, .1) !important;
}

.ui.progress .bar {
    height: 3px !important;
}

.security-progress-bar {
    position: absolute !important;
    border-radius: 0 !important;
    left: 0;
    right: 0;
    top: 0;
}

.scrolling.content {
    position: relative;
}

.security-progress-bar .bar {
    transition: width linear .5s;
}

/* Disable styles  */

.disabled input, input[disabled], textarea[disabled] {
    color: rgba(95, 95, 95, 0.87) !important;
    background: #ececec !important;
    border-color: rgba(34, 36, 38, .15) !important;
    opacity: 0.85 !important;
}

.driver-popup input[disabled], .driver-popup .disabled, .create-edit-form .disabled{
    background: none !important;
}

.label-disabled {
    color: rgba(95, 95, 95, 0.87) !important;
}

.ui.disabled.input input {
    color: rgba(95, 95, 95, 0.87) !important;
    background: #ececec !important;
    border-color: rgba(34, 36, 38, .15) !important;
}

.ui.fitted.checkbox:hover {
    cursor: pointer;
}

.ui.form .success.message {
    display: block !important;
}

.gate-characteristics_edit-modal {
    padding-top: 25px;
}

.standard-schedule__input .input {
    width: 80px;
}

.time-slot-popup-save-task {
    padding: 15px;
    width: 2000px;
}

.time-slot-popup-save-task__title {
    font-weight: bold;
}

.time-slot-popup-save-task__text {
    font-size: 14px;
    width: 250px;
}

.surface {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.surface-pdf {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.surface-text {
    text-align: left;
    display: flex;
    flex-direction: column;
    font-size: 20px;
}

.surface-text .loader {
    margin-top: 10px !important;
}

.surface-number {
    position: absolute;
    left: 10px;
    bottom: 10px;
}

.surface-text-header {
    font-size: 36px !important;
    margin-bottom: 50px;
    font-weight: bold;
}

.surface-text-number {
    padding-left: 8px;
    font-weight: bold;
    font-size: x-large;
}

.surface-confirm {
    border: 1px black solid;
    position: fixed;
    bottom: 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px;
    background: #f9fafb;
}

.surface-confirm button {
   margin-left: 10px !important;
}

.surface-confirm .message {
    margin: 0px !important;
}

.time-error {
    border-color: red !important;
}

input.time-error[disabled] {
    border-color: red !important;
}

.sub-permission{
    padding-left: 30px;
}

#surface-pdf-pages {
    height: 100vh;
}

#surface-pdf-pages canvas {
    display: block !important;
    margin: 10px;
}

.modal-headers-status {
    display: flex;
    justify-content: space-between;
}

.modal-headers-status :nth-child(2) {
    font-size: small;
}

.dropdown > .sizer {
    display: none;
}

.activity-dropdown {
    margin: 0 .25em 0 0;
}

.ui.disabled.selection.dropdown {
    background-color: #ececec !important;
}

.ui.disabled.segment {
    opacity: 1 !important;
    background-color: #ececec !important;
}

.form-data .field:last-child {
    display: flex;
}

.form-data .field .checkbox {
    margin-bottom: 10px;
    margin-top: auto;
}

.hc-checkbox {
    width: 37px;
    position: sticky !important;
    left: 0;
    box-shadow: 1px 0 0 0 rgba(34,36,38,.1);
    z-index: 2;
}

.hc-actions {
    position: sticky !important;
    right: 0;
}

.hc-actions button {
    max-width: 300px;
}

.hc-checkbox__row {
    background-color: white;
}

.last-row-td {
    border-bottom: 1px solid rgba(34, 36, 38, .1);
}

.d-flex {
    display: flex !important;
}

.j-c-center {
    justify-content: center;
}

.react-datepicker-popper {
    z-index: 11 !important;
}

.form-block {
    border-bottom: 0 !important;
    margin-bottom: 20px !important;
}

.react-datepicker__close-icon {
    right: 7px !important;
}

.d-block {
    display: block !important;
}

.scroll-block {
    overflow: hidden;
    overflow-y: auto;
}

.icon-delete {
    position: relative;
}

.icon-delete:before {
    position: absolute;
    font-family: Icons, serif;
    content: "\f00d";
    font-size: 8px;
    left: 22px;
    bottom: 6px;
}

.z-index-max {
    z-index: 101 !important;
}

.jc-c {
    display: flex;
    justify-content: center;
}

.border-left {
    border-left: 1px solid #ddd !important;
}

.popup-fix {
    z-index: 11 !important;
}

.cursor-pointer {
    cursor: pointer !important;
}

.white-space_pre-line {
    white-space: pre-line;
}

textarea:read-only {
    background-color: #ececec !important;
    color: rgba(95, 95, 95, 0.87) !important;
}

.w-100 {
    width: 100% !important;
}

.width-mc {
    width: max-content !important;
}