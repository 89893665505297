.special-fields {
    .message {
        margin: 15px 10px;

        .button {
            margin-top: 10px;
            padding: 8px 12px;
        }
    }
}

.pane-name {
    display: flex;
    justify-content: space-between;

    .delete.icon {
        margin-right: 0 !important;
    }
}

.sections-block {
    flex-direction: column;
}

.condition-group {
    &__delete {
        z-index: 2;
        height: 20px !important;
        position: absolute;
        left: auto;
        right: 7px;
        top: 7px;
    }

    &__divider {
        margin-top: 30px !important;
        margin-bottom: 30px !important;

        &:after, &:before {
            background: none !important;
        }
    }

    &__block {
        background-color: rgba(239, 239, 239, 0.27) !important;
        .segment {
            box-shadow: none !important;
        }
    }
}