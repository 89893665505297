.modal-tabs > .tab:not(.info) {
    max-height: 55vh;
    overflow: auto;
}

.info__task {
    > .content {
       padding: 14px !important;
    }
    > .actions {
        border-bottom-right-radius: 3px;
        border-bottom-left-radius: 3px;
    }
    padding: 0 !important;
}

.modal-grid {
    .table-block {
        max-height: 70vh;
    }
}