.bottom-line {
    border-bottom: 1px solid rgba(34,36,38,.1) !important;
}

.fix-size-icon {
    font-size: 1em !important;
}

.visible.menu.transition {
    max-height: 80vh;
    overflow-y: auto;
}